/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import { MDXProvider } from "@mdx-js/react"

import Header from "./header"
import "./layout.css"
import { CodeBlock, InlineCodeBlock } from "./CodeBlock"
import { CodeSandbox } from "./CodeSandbox"

const components = {
  pre: props => <div className="my-4" {...props} />,
  code: CodeBlock,
  inlineCode: InlineCodeBlock,
  CodeSandbox: CodeSandbox,
  blockquote: props => (
    <blockquote className="border-l-4 border-black pl-5 p-y2" {...props} />
  ),
  a: props => <a className="text-primary" {...props} />,
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet
        link={[
          {
            href:
              "https://fonts.googleapis.com/css?family=Domine|Montserrat&display=swap",
            rel: "stylesheet",
          },
        ]}
        bodyAttributes={{
          class: "font-body",
        }}
      />

      <Header siteTitle={data.site.siteMetadata.title} />

      <div className="flex flex-col flex-1 items-center">
        <main className="container flex-1 flex flex-col">
          <MDXProvider components={components}>{children}</MDXProvider>
        </main>
      </div>

      <footer className="text-center p-5 text-gray">
        © {new Date().getFullYear()} wasab.ai
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
