import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { TiCalendar } from "react-icons/ti"

const PostSnippet = ({ excerpt, date, title, url }) => {
  return (
    <div className="flex flex-col mb-4">
      <div className="flex flex-col md:flex-row md:items-center mb-3 justify-between">
        <h3 className="text-2xl">
          <Link to={url}>{title}</Link>
        </h3>

        <div className="flex flex-row items-center w-48 md:justify-end">
          <small className="mr-2">{date}</small>
          <TiCalendar />
        </div>
      </div>

      <p>{excerpt}</p>
    </div>
  )
}

const BlogListTemplate = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <SEO title="Recent posts" />

      <ul>
        {posts.map(post => (
          <PostSnippet
            key={post.node.fields.slug}
            excerpt={post.node.excerpt}
            date={post.node.frontmatter.date}
            title={post.node.frontmatter.title}
            url={`/posts/${post.node.fields.slug}`}
          />
        ))}
      </ul>

      <div
        className={
          "flex flex-row " + (isFirst ? "justify-end" : "justify-between")
        }
      >
        {!isFirst && (
          <Link
            className="bg-secondary self-start text-white rounded text-l px-4 py-2 font-bold"
            to={prevPage}
          >
            Previous
          </Link>
        )}

        {!isLast && (
          <Link
            className="bg-secondary self-end text-white rounded text-l px-4 py-2 font-bold"
            to={nextPage}
          >
            Next
          </Link>
        )}
      </div>
    </Layout>
  )
}

export default BlogListTemplate

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
          }
        }
      }
    }
  }
`
