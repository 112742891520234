import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"

export const CodeBlock = ({ children, className }) => {
  const language = className.replace(/language-/, "")

  return (
    <Highlight {...defaultProps} code={children} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{
            ...style,
            padding: "10px",
            width: "100%",
            overflow: "scroll",
          }}
        >
          {removeEmptyLastLine(tokens).map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => {
                return <span key={key} {...getTokenProps({ token, key })} />
              })}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}

export const InlineCodeBlock = ({ children, className }) => (
  <code className="font-bold bg-gray-400 px-2">{children}</code>
)

function removeEmptyLastLine(tokens) {
  const lastToken = tokens[tokens.length - 1]

  if (lastToken[lastToken.length - 1].empty) {
    const sliced = tokens.slice(0, tokens.length - 1)
    return sliced
  }

  return tokens
}
