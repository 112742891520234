import React from "react"

const validCodeSandboxPatterns = () => [
  /https:\/\/codesandbox\.io\/embed\/([a-zA-Z0-9]+)(\?.*)?/g,
  /https:\/\/codesandbox\.io\/s\/([a-zA-Z0-9]+)(\?.*)?/g,
]

const iframeStyle = {
  width: "100%",
  height: "500px",
  border: "0",
  borderRadius: "5px",
  overflow: "hidden",
}

export const CodeSandbox = React.memo(({ url }) => {
  let matches = null

  for (let pattern of validCodeSandboxPatterns()) {
    matches = pattern.exec(url)

    // First match
    if (matches != null) {
      break
    }
  }

  if (!matches) {
    return null
  }

  const id = matches[1]
  const options = matches[2] || ""
  const src = `https://codesandbox.io/embed/${id}${options}`

  return (
    <iframe
      src={src}
      title={id}
      style={iframeStyle}
      sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
    />
  )
})
