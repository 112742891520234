import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = props => {
  const { siteTitle } = props

  return (
    <header className="flex flex-col items-center">
      <div className="container flex justify-between flex-col md:flex-row">
        <h1 className="text-3xl">
          <Link to="/" className="text-primary">
            {siteTitle}
          </Link>
        </h1>

        <nav className="flex items-center mb-3">
          <ul className="flex flex-1 items-end">
            <li className="inline-block mr-6">
              <Link to="/" activeClassName="text-primary">
                blog
              </Link>
            </li>
            <li className="inline-block mr-6">
              <Link to="/about" activeClassName="text-primary">
                about
              </Link>
            </li>
            <li className="inline-block">
              <Link to="/creations" activeClassName="text-primary">
                creations
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
